module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mexican Timeshare Solutions","short_name":"MTS","start_url":"/","background_color":"#1e4699","theme_color":"#0e5099","display":"minimal-ui","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e530478a418227131c40921191a5cf9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-8429792-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"eventCategory":"Performance","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('../plugins/gatsby-plugin-marketing/gatsby-browser.js'),
      options: {"plugins":[],"tawkToId":"5c2d35a97a79fc1bddf30651","fbPixelId":"979605715465420","gaTrackingId":"UA-8429792-1","domain":"www.timesharescam.com","gTagID":"GTM-WJ6PCGG"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
